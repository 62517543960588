import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby"
import FooterLogo from "../svgs/footer-logo";
import InstagramLogo from "../svgs/instagram-logo";
import LinkedinLogo from "../svgs/linkedin-logo";
import Button  from "../buttons/button";
import ContactForm  from "../modals/contact-form-modal";
import "./footer.scss";

export default function Footer() {

	const data = useStaticQuery(graphql`
		query {
			wpMenu(name: {eq: "Footer Menu"}) {
				menuItems {
					nodes {
						title
						url
						label
						parentId
					}
				}
			}
			wp {
				themeGeneralSettings {
					themeOptions {
						footerDescription
					}
				}
			}
		}
	`);



	const year = new Date().getFullYear();

	return (
		<footer>
			<div className={`container`}>
				<div className="uk-child-width-1-2@m uk-grid columns-holder" data-uk-grid>
					<div>
						<div data-uk-toggle="target: #contact-form-modal-footer">
							<h2 className="uk-margin-small-bottom">
								<a href="#" className="read-more-link font-noe-display" aria-label="Contact us">Contact us</a>
							</h2>
						</div>
						<ContactForm />
						<div>
							<h2 className="uk-margin-small-bottom">
								<a href="/about/people/careers/" className="read-more-link font-noe-display" aria-label="Join the team">Join the team</a>
							</h2>
						</div>
					</div>
					<div className="uk-flex uk-flex-right@m footer-logo-wrp">
						<Link to="/" aria-label="Footer Logo">
							<FooterLogo />
						</Link>
					</div>
					<div>
						<Link className="btn-footer btn" to="/news-ideas/subscribe" aria-label="Subscribe">
							Subscribe
						</Link>
						{data?.wp?.themeGeneralSettings?.themeOptions?.footerDescription &&
							<div className="footer-description">
								<p dangerouslySetInnerHTML={{ __html: data?.wp?.themeGeneralSettings?.themeOptions?.footerDescription }} />
							</div>
						}
						<div className="social-logos-wrp">
							<a href="https://www.linkedin.com/company/apco-worldwide/" target="_blank" aria-label="Linkedin">
								<LinkedinLogo />
							</a>
							<a href="https://www.instagram.com/apcoworldwide/" target="_blank" aria-label="Instagram">
								<InstagramLogo />
							</a>
						</div>
					</div>
					<div className="footer-links">
						<div className="footer-links-wrp">
							{data?.wpMenu?.menuItems?.nodes.length > 0 && data?.wpMenu?.menuItems?.nodes.map((e, index) => (
								<div key={index}>
									<Link to={e.url} aria-label={e.label}>{e.label}</Link>
								</div>
							))}
						</div>
						<p className="copy-text">Copyright &#169;{`${year} All Rights Reserved, APCO Worldwide`}</p>
					</div>
				</div>
				<div className="be-ix-link-block"></div>
			</div>
		</footer>
	)
}
